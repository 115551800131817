import * as React from "react"
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import StandardSplashPage from "./StandardSplashPage";
import SuperdrugSplashPage from "./SuperdrugSplashPage";

interface SplashPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of the best SIM-only plans provided by the reseller to be
     * displayed on the splash screen.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function is responsible for rendering the splash page. This page will be the first
 * page a user visists and is used to show branded content and show how amazing the
 * Reseller is.
 */
const SplashPage = (props: SplashPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return (
            <SuperdrugSplashPage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
        )
    } else {
        return (
            <StandardSplashPage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
        )
    }
}

export default SplashPage
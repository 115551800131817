import * as React from "react"
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {CmsCDN} from "../../Model/CmsContent/CmsCDN";
import {Navigate} from "react-router-dom";
import {CmsPlanCardMappingSchema} from "../../Model/CmsContent/CmsPlanCardMappingSchema";
import {LatestBlogPostPane} from "../BlogListPage/BlogListPage";

interface SuperdrugSplashPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of the best SIM-only plans provided by the reseller to be
     * displayed on the splash screen.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the splash page for Superdrug.
 */
const SuperdrugSplashPage = (props: SuperdrugSplashPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    /**
     * Component will dynamically render the best SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-limited" template. Once converted, this HTML will be
     * injected into the "sp-pane-best-sim-only-plans" dynamic component to create the pane.
     */
    const BestSimOnlyPlans = (): JSX.Element | null => {
        const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

        if (optPlanToCardMapping) {
            const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema

            const limitedSimOnlyPlanCards = props.simOnlyPlans.map((simOnlyPlan, index) => {
                const optCardMapping = cardMappingOptions.homePageCardMapping
                    .find((planMapping) => planMapping.planCode === simOnlyPlan.packageCode || planMapping.default)
                const optCardContent = optCardMapping ? optHtmlContent(props.cmsContent, optCardMapping.cardReference) : undefined

                if (optCardMapping && optCardContent) {
                    return renderDynamicContent(optCardContent, [
                        {key: "DATA_ALLOWANCE", value: optCardMapping.overridePlanDataAllowance || simOnlyPlanDataLimit(simOnlyPlan)},
                        {key: "MONTHLY_PRICE", value: optCardMapping.overridePlanCost || simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                        {
                            key: "VIEW_PLAN_LINK",
                            value: SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.fauxPackageCode)
                        },
                        {key: "PLAN_COLOUR", value: optCardMapping.planColour},
                        {key: "LINK_SUFFIX", value: optCardMapping.linkSuffix || ""},
                        {key: "CARD_CLASS", value: optCardMapping.cardClass || ""},
                        {key: "TITLE_SUFFIX", value: optCardMapping.cardSubtitle || "data"},
                        {key: "FOOTER", value: optCardMapping.cardFooter || ""}
                    ])
                } else {
                    return null
                }
            })

            const filteredSimOnlyPlanCards = limitedSimOnlyPlanCards.flatMap((opt) => opt ? [opt] : [])

            return <React.Fragment>{filteredSimOnlyPlanCards}</React.Fragment>
        } else {
            return null
        }
    }

    React.useEffect(() => {
        // Trustpilot script is maintained by morons so we need to re-apply it here.
        const optTrustpilotCdn = props.cmsContent.find((content) =>
            content.reference === "trustpilot-cdn")
        if (optTrustpilotCdn && optTrustpilotCdn.jsonContent) {
            const cdnSource = optTrustpilotCdn.jsonContent as CmsCDN
            const script = document.createElement("script")
            script.src = cdnSource.href
            document.head.appendChild(script)
        }
    }, [])

    const optHomePage = optHtmlContent(props.cmsContent, "sp-page-home")

    if (!optHomePage) {
        return <Navigate to={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)}/>
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {renderDynamicContent(optHomePage, [
                    {key: "SIM_ONLY_PLANS", value: <BestSimOnlyPlans/>},
                    {key: "BLOG_LATEST", value: <LatestBlogPostPane cmsContent={props.cmsContent} />}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSplashPage
import * as React from "react"
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {Navigate} from "react-router-dom";
import {CmsPlanCardMappingSchema} from "../../Model/CmsContent/CmsPlanCardMappingSchema";

interface SuperdrugSimOnlyPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug-specific SIM-only plans page.
 */
const SuperdrugSimOnlyPlans = (props: SuperdrugSimOnlyPlansProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    /**
     * Component will dynamically render the SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-full" template. Once converted, this HTML will be injected
     * into the "sp-pane-sim-only-plans" dynamic component to create the pane.
     */
    const SimOnlyPlans = (): JSX.Element | null => {
        const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

        if (optPlanToCardMapping) {
            const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema

            const limitedSimOnlyPlanCards = props.simOnlyPlans.map((simOnlyPlan, index) => {
                const optCardMapping = cardMappingOptions.ourPlansCardMapping
                    .find((planMapping) => planMapping.planCode === simOnlyPlan.packageCode || planMapping.default)
                const optCardContent = optCardMapping ? optHtmlContent(props.cmsContent, optCardMapping.cardReference) : undefined

                if (optCardMapping && optCardContent) {
                    return renderDynamicContent(optCardContent, [
                        {key: "DATA_ALLOWANCE", value: optCardMapping.overridePlanDataAllowance || simOnlyPlanDataLimit(simOnlyPlan)},
                        {key: "MONTHLY_PRICE", value: optCardMapping.overridePlanCost || simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                        {key: "PLAN_CODE", value: simOnlyPlan.packageCode},
                        {key: "PLAN_COLOUR", value: optCardMapping.planColour},
                        {key: "LINK_SUFFIX", value: optCardMapping.linkSuffix || ""},
                        {key: "CARD_CLASS", value: optCardMapping.cardClass || ""},
                        {key: "TITLE_SUFFIX", value: optCardMapping.cardSubtitle || "data"},
                        {key: "FOOTER", value: optCardMapping.cardFooter || ""}
                    ])
                } else {
                    return null
                }
            })

            const filteredSimOnlyPlanCards = limitedSimOnlyPlanCards.flatMap((opt) => opt ? [opt] : [])

            return <React.Fragment>{filteredSimOnlyPlanCards}</React.Fragment>
        } else {
            return null
        }
    }

    const optOurPlansPage = optHtmlContent(props.cmsContent, "sp-page-our-plans")

    if (!optOurPlansPage) {
        return <Navigate to={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)}/>
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {renderDynamicContent(optOurPlansPage, [
                    {key: "SIM_ONLY_PLANS", value: <SimOnlyPlans/>}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimOnlyPlans
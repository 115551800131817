import * as React from "react"
import {Reseller} from "./Types/Reseller";
import {BrowserRouter} from "react-router-dom";
import {ApplicationContext} from "./ApplicationContext";
import SalesPortalRoutes, {SALES_PORTAL_ROUTES} from "./Routes/SalesPortalRoutes";
import {CmsContent, isCmsContent} from "./Model/CmsContent/CmsContent";
import {fetchCmsContentVersion, searchCmsContent} from "./Api/Cms/CmsContentApi";
import {isRequestError} from "./Model/RequestError";
import {SalesPortalConfiguration} from "./Model/Configuration/SalesPortalConfiguration";
import {saveSessionItem} from "./Utilities/SessionUtilities";
import {isArrayOf} from "./Utilities/TypeGuardUtilities";
import InternalErrorPage from "./Pages/InternalErrorPage/InternalErrorPage";
import {SimOnlyPlan} from "./Model/Sales/SimOnlyPlan";
import {fetchSimOnlyPlans} from "./Api/Sales/SimOnlyPlanApi";
import {searchCmsResources} from "./Api/Cms/CmsResourceApi";
import {isCmsCDN} from "./Model/CmsContent/CmsCDN";
import {readIndexDB, readLocalStorageItem, saveIndexDB, saveLocalStorageItem} from "./Utilities/LocalStorageUtilities";
import {refreshAccessToken} from "./Api/Hybris/HybrisAuthenticationApi";

interface SalesPortalProps {

    /**
     * Defines the context to be applied to application routes.
     */
    readonly context: string

    /**
     * Defines the reseller that is using this instance of the Sales Portal.
     */
    readonly reseller: Reseller

    /**
     * Base endpoint for the Sales Portal API.
     */
    readonly salesPortalApiDomain: string

    /**
     * Base endpoint for the LCS Content Management System.
     */
    readonly cmsApiDomain: string

    /**
     * The public URL that can be used to access this instance of the Sales Portal Webapp.
     */
    readonly salesPortalWebappPublicUrl: string

    /**
     * The public URL that can be used to access the relevant self care instance associated with this reseller.
     */
    readonly selfcareWebappPublicUrl: string | undefined

    /**
     * Base endpoint for the self care API.
     */
    readonly selfcareApiEndpoint: string | undefined

}

/**
 * Main function is responsible for rendering the Sales Portal webapp.
 */
const SalesPortal = (props: SalesPortalProps): JSX.Element | null => {

    const [resourcesLoading, setResourcesLoading] = React.useState<boolean>(true)
    const [resourcesFailedToLoad, setResourcesFailedToLoad] = React.useState(false)

    const [cmsContent, setCmsContent] = React.useState<CmsContent[]>([])
    const [cmsContentLoading, setCmsContentLoading] = React.useState<boolean>(true)
    const [cmsContentFailedToLoad, setCmsContentFailedToLoad] = React.useState<boolean>(false)

    const [simOnlyPlans, setSimOnlyPlans] = React.useState<SimOnlyPlan[]>([])
    const [simOnlyPlansLoading, setSimOnlyPlansLoading] = React.useState<boolean>(true)
    const [simOnlyPlansFailedToLoad, setSimOnlyPlansFailedToLoad] = React.useState<boolean>(false)

    const [bestSimOnlyPlans, setBestSimOnlyPlans] = React.useState<SimOnlyPlan[]>([])

    /**
     * Function will attempt to fetch an access token for the authenticated user.
     */
    const fetchAccessToken = async (): Promise<string | null> => {
        const optAccessToken = readLocalStorageItem(
            "access_token", (a: any): a is string => typeof a === "string")
        const optRefreshToken = readLocalStorageItem(
            "refresh_token", (a: any): a is string => typeof a === "string")
        const optExpiresAt = readLocalStorageItem(
            "expires_at", (a: any): a is number => typeof a === "number")

        if (!optAccessToken || !optRefreshToken || !optExpiresAt) {
            return null
        }

        const currentUtcTime = new Date().getUTCDate()
        if (optExpiresAt > currentUtcTime) {
            return Promise.resolve(optAccessToken)
        }

        const formattedPublicUrl = props.salesPortalWebappPublicUrl.endsWith("/") ?
            props.salesPortalWebappPublicUrl.substring(0, props.salesPortalWebappPublicUrl.length - 1) :
            props.salesPortalWebappPublicUrl

        return refreshAccessToken(props.salesPortalApiDomain, {
            refreshToken: optRefreshToken,
            redirectUrl: SALES_PORTAL_ROUTES.HybrisCallback(formattedPublicUrl)
        }).then((maybeToken) => {
            if (isRequestError(maybeToken) || maybeToken === null) {
                return null
            } else {
                return maybeToken.accessToken
            }
        })
    }

    // TODO This needs to be moved to the backend.
    /**
     * Utility function will sort the given list of SimOnlyPlan and return the
     * three plans with the largest data allowance.
     */
    const filterTopDataPlans = (simOnlyPlans: SimOnlyPlan[]): SimOnlyPlan[] => {
        const dataLimit = (simOnlyPlan: SimOnlyPlan): number | null => {
            const isUnlimitedPlan = simOnlyPlan.dataPlanLimit.planLimitValue === null
            const hasUnlimitedBolton = simOnlyPlan.planBolton !== null &&
                simOnlyPlan.planBolton.unit === "GB" &&
                simOnlyPlan.planBolton.value === null

            if (isUnlimitedPlan || hasUnlimitedBolton) {
                return null
            } else {
                const baseDataValue = simOnlyPlan.dataPlanLimit.planLimitValue!!
                const boltonDataValue = simOnlyPlan.planBolton && simOnlyPlan.planBolton.unit === "GB" ?
                    simOnlyPlan.planBolton.value!! : 0

                return baseDataValue + boltonDataValue
            }
        }

        const sortedPlans = simOnlyPlans.sort((planA, planB) => {
            const a = dataLimit(planA)
            const b = dataLimit(planB)

            // @ts-ignore
            return (a !== null) - (b !== null) || b - a
        })

        return sortedPlans.slice(0, 3)
    }

    /**
     * Function will setup the static/external resources for the website during the initial page load.
     */
    const setupResources = async () => {
        /**
         * Utility function will construct and apply a stylesheet to the DOM
         */
        const appendStylesheetToHead = async (href: string, integrity: string | undefined, crossOrigin: string | undefined) => {
            return new Promise((resolve, reject) => {
                const link = document.createElement("link")
                link.type = "text/css"
                link.rel = "stylesheet"
                link.integrity = integrity ?? ""
                link.crossOrigin = crossOrigin ?? ""
                link.href = href
                link.onload = () => resolve("")
                link.onerror = () => resolve("")

                document.head.appendChild(link)
            })
        }

        const createScript = (href: string, integrity: string | undefined, crossOrigin: string | undefined, onload: () => void, onerror: () => void) => {
            const script = document.createElement("script")
            script.src = href
            script.integrity = integrity ?? ""
            script.crossOrigin = crossOrigin ?? ""
            script.onload = onload
            script.onerror = onerror
            return script
        }

        /**
         * Utility function will construct and apply a script to the DOM
         */
        const appendScriptToHead = async (href: string, integrity: string | undefined, crossOrigin: string | undefined) => {
            return new Promise((resolve, reject) => {
                const script = createScript(href, integrity, crossOrigin, () => resolve(""), () => resolve(""))
                document.head.appendChild(script)
            })
        }

        /**
         * Utility function will construct and apply a script to the DOM in the body
         */
        const appendScriptToBody = async (href: string, integrity: string | undefined, crossOrigin: string | undefined) => {
            return new Promise((resolve, reject) => {
                const script = createScript(href, integrity, crossOrigin, () => resolve(""), () => resolve(""))
                document.getElementById("footer-scripts")?.appendChild(script)
            })
        }

        const cdnResources = await searchCmsContent(props.cmsApiDomain, props.reseller, {
            tags: ["cdn-stylesheet", "cdn-script"],
            pageNumber: 0
        })
        if (isRequestError(cdnResources)) {
            setResourcesFailedToLoad(true)
            setResourcesLoading(false)
            return
        } else {
            const stylesheets = cdnResources.flatMap((content) =>
                content.tags.includes("cdn-stylesheet") && content.jsonContent && isCmsCDN(content.jsonContent) ? [content.jsonContent] : []
            )
            for (const cdnResource of stylesheets) {
                await appendStylesheetToHead(cdnResource.href, cdnResource.integrity, cdnResource.crossOrigin);
            }

            const scripts = cdnResources.flatMap((content) =>
                content.tags.includes("cdn-script") && content.jsonContent && isCmsCDN(content.jsonContent) ? [content.jsonContent] : []
            )
            for (const cdnResource of scripts) {
                await appendScriptToHead(cdnResource.href, cdnResource.integrity, cdnResource.crossOrigin);
            }
        }

        const cmsResources = await searchCmsResources(props.cmsApiDomain, props.reseller, {
            tags: ["stylesheet", "javascript", "body-javascript"],
            pageNumber: 0
        })
        if (isRequestError(cmsResources) || cmsResources.length === 0) { // Always expecting at least one stylesheet
            setResourcesFailedToLoad(true)
            setResourcesLoading(false)
            return
        } else {
            const formattedReseller = props.reseller.charAt(0).toUpperCase() + props.reseller.slice(1)
            const formattedDomain = props.cmsApiDomain.endsWith("/") ?
                props.cmsApiDomain.substring(0, props.cmsApiDomain.length - 1) : props.cmsApiDomain

            const stylesheets = cmsResources.filter((resource) => resource.tags.includes("stylesheet"))
            for (const resource of stylesheets) {
                const url = `${formattedDomain}/public/${formattedReseller}/sales-portal/asset/${resource.reference}`
                await appendStylesheetToHead(url, undefined, undefined)
            }

            const scripts = cmsResources.filter((resource) => resource.tags.includes("javascript"))
            for (const resource of scripts) {
                const url = `${formattedDomain}/public/${formattedReseller}/sales-portal/asset/${resource.reference}`
                await appendScriptToHead(url, undefined, undefined)
            }

            const bodyScripts = cmsResources.filter((resource) => resource.tags.includes("body-javascript"))
            for (const resource of bodyScripts) {
                const url = `${formattedDomain}/public/${formattedReseller}/sales-portal/asset/${resource.reference}`
                await appendScriptToBody(url, undefined, undefined)
            }
        }

        setResourcesLoading(false)
    }

    /**
     * Function will setup the available content from the CMS during the initial page load.
     */
    const setupContent = async () => {
        const refreshCmsContent = async () => {
            const content = await searchCmsContent(props.cmsApiDomain, props.reseller, {pageNumber: 0})

            if (isRequestError(content) || content.length === 0) {
                setCmsContentFailedToLoad(true)
            } else {
                setCmsContent(content)
                await saveIndexDB("cms-content", content)
            }
        }

        const optContentVersion = readLocalStorageItem(
            "content-version", (a: any): a is string => typeof a === "string" && a.length > 0)
        const maybeCmsContentData = await readIndexDB(
            "cms-content", (a: any): a is CmsContent[] => isArrayOf(a, isCmsContent))

        if (maybeCmsContentData === null) {
            await refreshCmsContent()
        } else {
            const maybeVersion = await fetchCmsContentVersion(props.cmsApiDomain, props.reseller)

            if (typeof maybeVersion !== "string") { // Content version API has failed, try to get all content.
                await refreshCmsContent()
            } else if (maybeVersion !== optContentVersion) {
                saveLocalStorageItem("content-version", optContentVersion)
                await refreshCmsContent()
            } else { // Version hasn't changed, try an read content from local storage
                setCmsContent(maybeCmsContentData)
            }
        }
        setCmsContentLoading(false)
    }

    /**
     * Function will setup the available SIM-only plans during the initial page load.
     */
    const setupSimOnlyPlans = async () => {
        const simOnlyPlans = await fetchSimOnlyPlans(props.reseller, props.salesPortalApiDomain)

        if (isRequestError(simOnlyPlans) || simOnlyPlans.length === 0) {
            setSimOnlyPlansFailedToLoad(true)
        } else {
            setSimOnlyPlans(simOnlyPlans)
            setBestSimOnlyPlans(filterTopDataPlans(simOnlyPlans))
            saveSessionItem("sim-only-plans", simOnlyPlans)
        }
        setSimOnlyPlansLoading(false)
    }

    const setupFavicon = async () => {
        let link = document.querySelector("link[rel~='icon']")
        if(link && link instanceof HTMLLinkElement) {
            const cmsUrl = props.cmsApiDomain.endsWith('/') ? props.cmsApiDomain.slice(0, -1) : props.cmsApiDomain
            link.href = `${cmsUrl}/public/${props.reseller}/sales-portal/asset/favicon`;
        }
    }

    React.useEffect(() => {
        setupResources()
        setupContent()
        setupSimOnlyPlans()
        setupFavicon()
    }, [])

    const salesPortalConfig = cmsContent.find((content) => content.reference === "sales-portal-config")

    if (resourcesLoading || cmsContentLoading || simOnlyPlansLoading) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else if (salesPortalConfig === undefined || resourcesFailedToLoad || cmsContentFailedToLoad || simOnlyPlansFailedToLoad) {
        return (
            <InternalErrorPage cmsContent={cmsContent}/>
        )
    } else {
        const appConfig = salesPortalConfig.jsonContent as SalesPortalConfiguration

        return (
            <BrowserRouter>
                <ApplicationContext.Provider value={{
                    urlContext: props.context,
                    reseller: props.reseller,
                    salesPortalApiDomain: props.salesPortalApiDomain,
                    cmsApiDomain: props.cmsApiDomain,
                    salesPortalWebappPublicUrl: props.salesPortalWebappPublicUrl,
                    selfcareWebappPublicUrl: props.selfcareWebappPublicUrl,
                    selfcareApiEndpoint: props.selfcareApiEndpoint,
                    appConfig: appConfig,
                    accessToken: () => {
                        if (appConfig.authenticationConfiguration.hybrisAuthenticationConfiguration) {
                            return fetchAccessToken()
                        } else {
                            return Promise.resolve(null)
                        }
                    }
                }}>
                    <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                        <SalesPortalRoutes
                            cmsContent={cmsContent}
                            simOnlyPlans={simOnlyPlans}
                            bestSimOnlyPlans={bestSimOnlyPlans}
                        />
                    </div>
                </ApplicationContext.Provider>
            </BrowserRouter>
        )
    }
}

export default SalesPortal